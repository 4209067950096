<template>
  <div>
    <div class="center-block-date">
      <Select v-model="queryModel.startDate" style="width:100px" size="small" @on-change="handleChangeDate">
        <Option v-for="(date,index) in startDateArray" :key="index" :value="date.key">{{date.value}}</Option>
      </Select>
      <span class="m-l-5 m-r-5">至</span>
      <Select v-model="queryModel.endDate" style="width:100px" size="small" @on-change="handleChangeDate">
        <Option v-for="(date,index) in endDateArray" :key="index" :value="date.key">{{date.value}}</Option>
      </Select>
    </div>

    <!-- 显示地铁线路图 -->
    <SvgLine v-if="showMapType===1" ref="svgline" @map-finished="handleMapLoadFinished" @on-click-station="handleClickStation"/>
    <GeoMap v-else ref="geoMap" @map-finished="handleMapLoadFinished"/>

    <!-- 显示运维上刊情况 -->
    <SvgStationDemand ref="svgStationDemand" :actionDate="gbId"/>
  </div>
</template>

<script>
import SvgLine from '@/components/svg/SvgLine'
import GeoMap from '@/components/map/GeoMap'
import SvgStationDemand from '@/components/svg/SvgStationDemand'

import { getStartDateList, getEndDateList } from '@/api/dw/time'
import { listAssetFinishStationByDate } from '@/api/dw/maintain'
import { listCompanyAssets } from '@/api/dw/datav'

export default {
  components: {
    SvgLine, GeoMap, SvgStationDemand
  },
  data () {
    return {
      showMapType: this.$store.getters.token.publisherSetting.mediaType,
      publisherId: this.$store.getters.token.userInfo.publisherId,
      loginCompanyId: this.$store.getters.token.userInfo.companyId,
      mapFinished: false,
      startDateArray: [],
      endDateArray: [],

      queryModel: {
        endDate: '',
        startDate: ''
      }
    }
  },
  created () {
    this.queryModel.startDate = this.searchSchedule.startDate
    this.queryModel.endDate = this.searchSchedule.endDate
    this.initPageData()
  },
  methods: {
    initPageData () {
      const postData = { publisherId: this.publisherId }
      getStartDateList(postData).then(res => {
        this.startDateArray = res
      })
      getEndDateList(postData).then(res => {
        this.endDateArray = res
      })
    },
    handleChangeDate () {
      const schedule = {
        startDate: this.queryModel.startDate,
        endDate: this.queryModel.endDate
      }

      this.$store.commit('set_situation_companyId', (this.loginCompanyId === this.publisherId) ? '0' : this.loginCompanyId)
      this.$store.commit('set_situation_assetId', '0')

      this.$store.commit('set_situation_searchSchedule', schedule)
    },
    handleMapLoadFinished (val) {
      this.mapFinished = val
      if (val) {
        // 执行线路隐藏操作
        listCompanyAssets({ publisherId: this.publisherId }).then(res => {
          const assetIds = []
          let relateIds = []
          res.forEach(item => {
            relateIds = item.relateIds.split(',').map(x => parseInt(x))

            relateIds.forEach(element => {
              if (!assetIds.includes(element)) {
                assetIds.push(element)
              }
            })
          })
          this.$refs.svgline.hideOtherAsset(assetIds)
        })
      }
    },
    loadAssetFinishStationByDate () {
      const queryModel = {
        assetId: this.assetId,
        workDate: this.gbId,
        companyId: this.companyId,
        publisherId: this.publisherId,
        supplierId: null
      }

      listAssetFinishStationByDate(queryModel).then(res => {
        const formatStations = res.map(x => {
          return {
            quantity: x.value,
            stationId: x.id,
            stationName: x.name
          }
        })

        if (this.showMapType === 1) {
          this.$refs.svgline.loadResourceStations(formatStations)
          // 获取中心站点， 并执行居中处理
          let centerIndex = 0
          if (formatStations.length > 1) {
            centerIndex = parseInt(formatStations.length / 2)
          }
          this.$refs.svgline.handleMapGetCenterByStation(formatStations[centerIndex].stationId)
        } else {
          this.$refs.geoMap.loadResourceStations(formatStations)
        }
      })
    },
    handleClickStation (stationId) { // 地图点击站点事件
      this.$refs.svgStationDemand.handleShowStationDemand(stationId)
    }
  },
  computed: {
    searchSchedule () {
      return this.$store.state.situationWeb.searchSchedule
    },
    assetId () {
      return this.$store.state.situationWeb.assetId
    },
    companyId () {
      return this.$store.state.situationWeb.companyId
    },
    gbId () {
      return this.$store.state.situationWeb.gbId
    }
  },
  watch: {
    gbId (val) {
      if (val) {
        // 开始加载上刊点位信息
        this.loadAssetFinishStationByDate(val)
      } else {
        if (this.showMapType === 1) {
          this.$refs.svgline.loadResourceStations([])
        } else {
          this.$refs.geoMap.loadResourceStations([])
        }
      }
    }
  }
}
</script>

<style>

</style>
